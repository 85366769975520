import { Card, Table, Text } from "@nextui-org/react";
import * as React from "react";
import { getOrderRef } from "./index";

const step1Request = {
  endUserIp: "127.0.0.1",
  redirectURL: "https://www.bob.com/signedin",
  userVisibleData:
    "RGF0ZTogMjAyMi0wOC0zMFQwNzowMjoyMS45ODFaIFNpZ24gaW4gdG8gc3VwZXIgd2ViIHNpdGUgZG90IGNvbQ==",
};

const step1Response = {
  orderRef: "time-ladder-service",
  endUserIp: "127.0.0.1",
  endUserURL: "https://idp.ravenrebels.com?orderRef=time-ladder-service",
  userVisibleData:
    "RGF0ZTogMjAyMy0wMi0yNlQxMzozMzo0OC44MzlaIFNpZ24gaW4gdG8gc3VwZXIgd2ViIHNpdGUgZG90IGNvbQ==",
  hintCode: "outstandingTransaction",
  redirectURL: "https://www.bob.com/signedin",
  createdDate: "2023-02-26T13:33:48.898Z",
};

const step3Response = {
  completionData: {
    user: {
      personalNumber: "FREN#WONDERLAND",
      name: "FREN#WONDERLAND",
      givenName: "R9ViFY6HbQqz5N8h159g7PFGNymN6khaRU",
      surname: "FREN#WONDERLAND",
    },
    device: {
      ipAddress: "::ffff:172.18.178.126",
    },
    cert: {
      notBefore: "1660638691933",
      notAfter: "5980638817306",
    },
    signature:
      "IGE9RbrvW55OlmTc59ZampTuhliuePkxZJWdWGE8S+JqQdlOk5qdu7YZOmHb9J/1cJA+joQ08DHfVI4FQ19A0Do=",
    address: "R9ViFY6HbQqz5N8h159g7PFGNymN6khaRU",
    nft: "FREN#WONDERLAND",
    meta: {
      name: "FREN#WONDERLAND",
      amount: 1,
      units: 0,
      reissuable: 0,
      has_ipfs: 1,
      ipfs_hash: "QmPdwHENQ4jvDEsxNqQfSo9jXfwYuysDvcY4dWQFX6fn8K",
    },
    ocspResponse: "",
  },
  userVisibleData: "U2lnbiBpbiB0byBkZW1vIDEwOjI3OjMz",
  orderRef: "time-ladder-service",
  hintCode: "",
  status: "complete",
};
export function Loading({ loaded }) {
  const [title, setTitle] = React.useState("Loading");
  const [errorText, setErrorText] = React.useState("");
  React.useEffect(() => {
    const orderRef = getOrderRef();
    if (!orderRef) {
      setErrorText("ERROR no order reference parameter 'orderRef'");
      return null;
    }

    const URL = "/orders/" + orderRef;

    async function stuff() {
      const response = await fetch(URL);
      const obj = await response.json();
      loaded(obj);
    }

    stuff();
  }, []);
  return (
    <div>
      {!errorText && <h2>{title}</h2>}
      {errorText && <h3>{errorText}</h3>}
      <style>
        {`
  code{
    display:block;
    font-size: 80%;
    margin-bottom: 20px;
  }
  `}
      </style>
      <Text h1 style={{ marginTop: "200px" }}>
        Technical documentation
      </Text>
      <p>If you want to add Ravencoin Sign in to your web site do this</p>
      <Text h2>Background</Text>
      <p>
        There are three actors in the sign in flow. Example:{" "}
        <q>User Alice wants to sign in to web site www.bob.com</q>
      </p>
      <ol>
        <li>
          <strong>End-user</strong>, that is Alice
        </li>
        <li>
          <strong>Relying Party</strong>, fancy name for www.bob.com
        </li>
        <li>
          <strong>The Identity Provider</strong> (IDP, idp.ravenrebels.com)
        </li>
      </ol>
      <Text h2>Redirect flow</Text>
      <Text h3>Short version</Text>
      <ol>
        <li>Alice wants to sign in to www.bob.com</li>
        <li>
          www.bob.com creates an authentication order at IDP and informs IDP
          where to redirect Alice after signing in <q>redirectURL</q>.
        </li>
        <li>Alice is directed to IDP by www.bob.com</li>
        <li>Alice signs in over at IDP</li>
        <li>
          When Alice has signed in, IDP redirects Alice back to bob.com with
          order ref as query parameter
        </li>

        <li>
          Bob.com now requests information from IDP regarding this order (by
          orderRef)
        </li>
        <li>
          Bob.com updates the HTTP session for Alice with the name of her NFT,
          her Ravencoin address and she is signed in to bob.com
        </li>
      </ol>
      <h3>Example Node.js app</h3>
      <a href="https://github.com/ravenrebels/demo-site-sign-in-simple">
        A very simple and naive example of signing in using Ravencoin NFTs
      </a>
      <h3>Long version</h3>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Header>
          <Text h4>Step 1: Create an authentication order</Text>
        </Card.Header>
        <Card.Body>
          <Text h5>The Request</Text>
          <p>
            Relying party HTTP Posts a request to the IDP for an authentication
            order to URL 
            <code>https://idp.ravenrebels.com/rp/v5.1/sign</code>
          </p>
          <p>The request body is a JSON object that contains:</p>
          <ul>
            <li>
              <q>userVisibleData</q>, this is the actual text message the user
              should sign, base64 encoded. For example
              <q>Sign in to www.bob.com TIMESTAMP</q>
            </li>
            <li>
              <q>redirectURL</q>, URL to where the user should land after
              authenticating, for example https://.......com/landing_signedin
            </li>
            <li>
              <q>endUserIp</q>, IP-address of the user, semi-optional, you can
              hard code it to <q>127.0.0.1</q>
            </li>
          </ul>
          <code>{JSON.stringify(step1Request, null, 4)}</code>

          <Text h5>The Response</Text>
          <p>
            Notice <q>endUserURL</q> is where the Relying Party should redirect
            the End-User
            <code>{JSON.stringify(step1Response, null, 4)}</code>
          </p>
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Header>
          <Text h4>Step 2: redirect user to IDP</Text>
        </Card.Header>
        <Card.Body>
          <p>
            The response in Step 1 contained an <q>endUserURL</q> attribute. We
            redirect the End-User to <q>endUserURL</q> which is the "sign in
            form" at the IDP.
          </p>
        </Card.Body>
      </Card>
      <Card style={{ marginBottom: "20px" }}>
        <Card.Header>
          <Text h4>Step 3: End-User is redirected back to Relying Party</Text>
        </Card.Header>
        <Card.Body>
          <p>
            When the End-User has authenticated over at IDP, she is redirected
            back to the Relying Party.
          </p>
          <p>
            IDP has appended the order ref in the URL as a query string
            parameter
          </p>
          <code>https://www.bob.com/signedin?orderRef=time-ladder-service</code>
          <p>
            Relying Party ask the IDP for more information regarding this order
            by sending an HTTP Post request to
            <code>https://idp.ravenrebels.com/rp/v5.1/collect</code>
            The body of the request must be a JSON object with the attribute{" "}
            <q>orderRef</q>
            <code>
              {`{
"orderRef": "time-ladder-service",
}`}
            </code>
          </p>
          <Text h5>Response example</Text>
          Note
          <ol>
            <li>NFT = response.completionData.nft</li>
            <li>Ravencoin address = response.completionData.address</li>
            <li>Signature = response.completionData.signature</li>
          </ol>
          <p>
            <code>{JSON.stringify(step3Response, null, 4)}</code>
          </p>
        </Card.Body>
      </Card>
    </div>
  );
}
