import { Button, Grid, Input, Textarea } from "@nextui-org/react";
import * as React from "react";

export default function Step2({ next }) {
  const [address, setAddress] = React.useState(null);
  const [nft, setNFT] = React.useState("");
  // 2. Use at the root of your app

  const onChangeNFT = async (event) => {
    const value = event.target.value;
    setNFT(value);
  };
  return (
    <div>
      <h2>Step 2 of 3</h2>
      <h4>What is the name of your NFT? (Unique Asset)</h4>
      <Grid.Container gap={1}>
        <Grid xs={12}>
          <Input
            label="NFT (case sensitive)"
            bordered
            name="nft"
            onChange={onChangeNFT}
            defaultValue={nft}
            fullWidth
          ></Input>
        </Grid>
        <Grid xs={12}>
          <div>{address}</div>
        </Grid>
        <Grid xs={12} sm={6}>
          <Button
            color="primary"
            onPress={async () => {
              const URL = "/address?nft=" + encodeURIComponent(nft);
              const response = await fetch(URL);

              console.log("URL", URL);
              const data = await response.json();

              if (data.address && data.address !== "0") {
                setAddress(data.address);

                next({
                  nft,
                  address: data.address,
                });
              } else {
                setAddress(null);
                alert("Cannot find nft " + nft);
              }
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid.Container>
    </div>
  );
}
