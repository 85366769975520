import { Button, Grid, Spacer, Text, Textarea } from "@nextui-org/react";
import * as React from "react";
import { Card } from "@nextui-org/react";
import QRCode from "react-qr-code";
export default function Step1({ message, next, order }) {
  const jsonText = JSON.stringify(order);

  return (
    <div>
      <h2>Step 1 of 3</h2>
      <h4>You are about to sign this message</h4>
      <div>
        <Card variant="bordered">
          <Card.Body>
            <Text h3>{message}</Text>
          </Card.Body>
        </Card>
      </div>

      <Spacer y={1} />

      <Grid.Container gap={0}>
        <Grid xs={12} sm={6}>
          <Button color="primary" onPress={next} css={{ marginTop: "10px" }}>
            Next
          </Button>
        </Grid>
        <Grid xs={12} sm={6}>
          <Button
            color="primary"
            onPress={() => {
              if (window.opener === null) {
                alert("Please close this window");
              } else {
                window.close();
              }
            }}
            css={{ marginTop: "10px" }}
          >
            Cancel
          </Button>
        </Grid>

        <Grid xs={12}>
          <div
            style={{ display: "block", marginTop: "80px" }}
            onClick={() => {
              const promise = navigator.clipboard.writeText(order.orderRef);
              promise.then((_) => {
                alert("Copied " + order.orderRef + " to memory");
              });
              promise.catch((_) => {
                alert("Could NOT copy order ref to memory");
              });
            }}
          >
            <span
              style={{
                display: "inline-block",
                marginTop: "0px",
                textAlign: "center",
                padding: "15px",

                background: "white",
              }}
            >
              <QRCode size={192} value={jsonText} />
            </span>

            <Text
              css={{
                display: "block",
                paddingLeft: "15px",
              }}
            >
              Ravencoin Authenticator (coming soon)
            </Text>
          </div>
        </Grid>
      </Grid.Container>
    </div>
  );
}
