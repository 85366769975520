import { Card, Grid, Spacer, Text } from "@nextui-org/react";
import * as React from "react";
export default function Success({ nft }) {
  console.log("Success and nft is", nft);
  // 2. Use at the root of your app
  return (
    <div>
      <h2>Success</h2>

      <Spacer y={1} />

      <Grid.Container gap={0}>
        <Grid xs={12}>
          <Card variant="bordered">
            <Card.Body>
              <Text h4>You are authenticated as</Text>
              <Text
                size={20}
                css={{
                  textGradient: "45deg, $purple600 -20%, $pink600 100%",
                }}
                weight="bold"
              >
                {nft}
              </Text>
              <Text>Please close this window</Text>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
    </div>
  );
}
