import {
  Container,
  createTheme,
  NextUIProvider,
  Switch,
} from "@nextui-org/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "./style.css";
import { Grid, Card, Text } from "@nextui-org/react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { Loading } from "./Loading";
import Step3 from "./Step3";
import postData from "./postData";
import Success from "./Success";

import "boxicons";

const lightTheme = createTheme({
  type: "light",
});
const darkTheme = createTheme({
  type: "dark",
});
enum Routes {
  Error,
  Loading,
  Step1,
  Step2,
  Step3,
  Success,
}
function Error({ error }) {
  return <div>{error}</div>;
}
export function getOrderRef() {
  const params = window.location.search;
  const p = new URLSearchParams(params);
  const orderRef = p.get("orderRef");
  return orderRef;
}

function checkStatusInBackground(error, success) {
  //A very naive way of checking state, but it works..
  const statusInterval = setInterval(async () => {
    //Poll status from server every 2 seconds until complete or failed.
    //const URL = "/orders/" + getOrderRef();
    const URL = "/rp/v5.1/collect";

    //If no orderRef, just ignore
    if (!getOrderRef()) {
      return;
    }

    //const response = await fetch(URL);
    const data = await postData(URL, { orderRef: getOrderRef() });

    if (data.status === "complete") {
      clearInterval(statusInterval);
      console.log(data);
      //If data contains redirectURL than redirect the user
      if (data.redirectURL) {
        const prefix = data.redirectURL.indexOf("?") === -1 ? "?" : "&";
        window.location.href =
          data.redirectURL +
          prefix +
          "orderRef=" +
          encodeURIComponent(data.orderRef);
        return;
      } else {
        success(data);
      }
    }

    if (data.error) {
      clearInterval(statusInterval);
      error();
    }
  }, 2000);
}

let defaultTheme = darkTheme;

/*
//uncomment if we want dark/light mode to reflect what the user has chosen
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  // dark mode
  defaultTheme = darkTheme;
}
*/
function App() {
  const [route, setRoute] = React.useState(Routes.Loading);
  const [theme, setTheme] = React.useState(defaultTheme);
  const [order, setOrder] = React.useState(null);
  const [nft, setNFT] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState("");
  const [orderRef, setOrderRef] = React.useState(null);

  React.useEffect(() => {
    setOrderRef(getOrderRef());
    const error = () => {};
    const success = (data) => {
      setNFT(data.completionData.user.name);
      setAddress(data.address);
      setRoute(Routes.Success);
    };
    checkStatusInBackground(error, success);
  }, []);
  //  css={{ maxWidth: "600px", background: "$colors$primary" }}
  return (
    <NextUIProvider theme={theme}>
      <Container
        sm
        css={{
          marginTop: "10px",
          padding: "10px",
          borderRadius: "5px",
          border: "1px dotted  $purple600",
        }}
      >
        <Grid.Container gap={0} justify="center" css={{ marginTop: "10px" }}>
          <Grid xs={10}>
            <Text
              h1
              size={30}
              css={{
                textGradient: "45deg, $purple600 -20%, $pink600 100%",
              }}
              weight="bold"
            >
              Ravencoin sign in
            </Text>
          </Grid>
          <Grid xs={2}>
            <Switch
              initialChecked={theme === darkTheme}
              onChange={(event) => {
                const t =
                  event.target.checked === true ? darkTheme : lightTheme;
                setTheme(t);
              }}
            />
          </Grid>
        </Grid.Container>

        {route === Routes.Error && <Error error={error} />}
        {route === Routes.Loading && (
          <Loading
            loaded={(d) => {
              setOrder(d);

              if (d.error === "Order no found") {
                setError(d.error + " perhaps it has expired");
                setRoute(Routes.Error);
              } else {
                setRoute(Routes.Step1);
              }
            }}
          />
        )}
        {route === Routes.Step1 && (
          <Step1
            message={atob(order.userVisibleData)}
            next={() => {
              setRoute(Routes.Step2);
            }}
            order={order}
          />
        )}
        {route === Routes.Step2 && (
          <Step2
            next={(data) => {
              setAddress(data.address);
              setNFT(data.nft);
              setRoute(Routes.Step3);
            }}
          />
        )}

        {route === Routes.Step3 && (
          <Step3
            address={address}
            message={atob(order.userVisibleData)}
            next={() => {
              setRoute(Routes.Step3);
            }}
            nft={nft}
            orderRef={orderRef}
          />
        )}

        {route === Routes.Success && <Success nft={nft}></Success>}
      </Container>
    </NextUIProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
