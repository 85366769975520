import { Button, Card, Grid, Input, Textarea } from "@nextui-org/react";
import * as React from "react";
import postData from "../gui/postData";

export default function Step3({ address, message, nft, next, orderRef }) {
  const [signature, setSignature] = React.useState(null);
  return (
    <div>
      <Grid.Container gap={1}>
        <Grid xs={12}>
          <h2>Step 3 of 3</h2>
        </Grid>
        <Grid xs={12}>
          <h3>Please sign this message</h3>
        </Grid>
        <Grid xs={12}>
          <Card variant="bordered" css={{ mw: "400px" }}>
            <Card.Body>
              <Textarea
                bordered
                fullWidth
                initialValue={message}
                label="Message (click to copy)"
                onClick={() => {
                  navigator.clipboard.writeText(message);
                }}
                readOnly
              ></Textarea>
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={12}>
          <h3>....with this address</h3>
        </Grid>
        <Grid xs={12}>
          <Card
            variant="bordered"
            css={{ mw: "400px" }}
            onPress={() => {
              navigator.clipboard.writeText(address);
            }}
          >
            <Card.Body>{address}</Card.Body>
          </Card>
        </Grid>
        <Grid xs={12}>
          <h3>Signature</h3>
        </Grid>
        <Grid xs={12}>
          <Input
            bordered
            label="Signature"
            fullWidth
            onChange={(event) => {
              const value = event.target.value;
              setSignature(value);
            }}
          ></Input>
        </Grid>

        <Grid xs={12}>
          <Button
            onPress={() => {
              const obj = {
                address,
                message,
                nft,
                orderRef,
                signature,
              };

              const promise = postData("/authenticate", obj);

              function onError(data) {}

              //This is kind of bad, that a HTTP 400 is a "success"
              promise.then((d) => {
                if (d.error) {
                  alert(d.error);
                } else {
                  setTimeout(window.close, 5000);
                }
              });

              promise.catch(() => {
                alert("Something went wrong");
              });
            }}
            color="primary"
          >
            Sign
          </Button>
        </Grid>
      </Grid.Container>
    </div>
  );
}
